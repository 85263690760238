<template>
  <div class="risk-warning-naga">
      <div class="container">
          <div class="risk-warning-naga-content">
              <div class="risk-warning-naga-content-img">
                  <img src="../../../assets/lps/naga/risk-warning.svg" alt="">
              </div>

              <div class="risk-warning-naga-content-p">
                  <p><strong>RISK WARNING:</strong> 77.95% of retail client investors lose money when trading CFDs with this provider. You should consider whether you can afford to take the high risk of losing your money.</p>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
    .risk-warning-naga {
        background: #F3F3F3;
        padding: 10px 0;
    
    }

    .risk-warning-naga-content {
        display: flex;
        align-items: center;
        max-width: 1640px;
        width: 100%;
        margin: 0 auto;
    }

    .risk-warning-naga-content-img img {
        margin-right: 10px;
    }

       @media(max-width:1024px){
           .risk-warning-naga-content-p p {
               font-size: 14px;
           }
   }

   @media(max-width:500px){
       .risk-warning-naga-content {
           flex-direction: column;
           align-items: flex-start;

       }

         .risk-warning-naga-content-img img {
             margin-bottom: 10px;
    }
   }

 
</style>